import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Noah Wong",
  "date": "2018-04-02 UTC -8",
  "length": 5,
  "location": "Portland, OR",
  "path": "/colton-birthday/",
  "title": "Welcome Colton Wong!",
  "tags": ["personal", "boys", "lifestyle"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Isn't that fantastic? You can just push a little tree out of your brush like that. I started painting as a hobby when I was little. I didn't know I had any talent. I believe talent is just a pursued interest. Anybody can do what I do. We don't really know where this goes - and I'm not sure we really care.`}</p>
    <p>{`That's a son of a gun of a cloud. For the lack of a better word I call them hangy downs. It's so important to do something every day that will make you happy.`}</p>
    <p>{`Let's build some happy little clouds up here. If you don't think every day is a good day - try missing a few. You'll see. Any little thing can be your friend if you let it be. You don't have to spend all your time thinking about what you're doing, you just let it happen. Only God can make a tree - but you can paint one.`}</p>
    <p>{`Almost everything is going to happen for you automatically - you don't have to spend any time working or worrying. That's what makes life fun. That you can make these decisions. That you can create the world that you want. Let your imagination just wonder around when you're doing these things. There we go. Paint anything you want on the canvas. Create your own world.`}</p>
    <p>{`We don't have to be concerned about it. We just have to let it fall where it will. Nice little clouds playing around in the sky. And I know you're saying, 'Oh Bob, you've done it this time.' And you may be right.`}</p>
    <p>{`Every single thing in the world has its own personality - and it is up to you to make friends with the little rascals. I like to beat the brush. All kinds of happy little splashes. Mountains are so simple, they're hard.`}</p>
    <p>{`It's hard to see things when you're too close. Take a step back and look. Fluff it up a little and hypnotize it. When you do it your way you can go anywhere you choose.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAgX/xAAXAQEBAQEAAAAAAAAAAAAAAAACAAED/9oADAMBAAIQAxAAAAGHeTSOkl2nB6xUgF//xAAcEAACAgMBAQAAAAAAAAAAAAABAgADERIhEyL/2gAIAQEAAQUCV/OA7w14mSz19lh+kOpVuFQZ/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAQEf/aAAgBAwEBPwEuEZ//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BbVR//8QAGBAAAwEBAAAAAAAAAAAAAAAAABAhETH/2gAIAQEABj8Cwq04oqf/xAAbEAADAAMBAQAAAAAAAAAAAAAAAREhMUFRYf/aAAgBAQABPyHh8GXKdKvbobBVYpgvG7aQdPg9n0PeiRkdj//aAAwDAQACAAMAAAAQyAg+/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQESH/2gAIAQMBAT8QAnbiGz//xAAXEQEAAwAAAAAAAAAAAAAAAAABABAR/9oACAECAQE/EFS6nJ//xAAdEAEAAwEBAAMBAAAAAAAAAAABABEhMUFhcYGR/9oACAEBAAE/EK9QolzquS6BCm+/2ZvAtHpEXgNL34jwoDYE+/KiV+8fkO1QYGFtCeXHQl4u+7P/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Colton Birthday",
          "title": "Colton Birthday",
          "src": "/static/16c2848fa4efd1580985ef3fb945db77/8e1fc/colton_birthday_1.jpg",
          "srcSet": ["/static/16c2848fa4efd1580985ef3fb945db77/863e1/colton_birthday_1.jpg 225w", "/static/16c2848fa4efd1580985ef3fb945db77/20e5d/colton_birthday_1.jpg 450w", "/static/16c2848fa4efd1580985ef3fb945db77/8e1fc/colton_birthday_1.jpg 900w", "/static/16c2848fa4efd1580985ef3fb945db77/47311/colton_birthday_1.jpg 1080w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAVcu6wVKlEZf/8QAGhABAQACAwAAAAAAAAAAAAAAAgEAAxESMf/aAAgBAQABBQJ6utqpvuTWdxI4lAN//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AWx//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAgICAwAAAAAAAAAAAAAAAREAAiExEEJi/9oACAEBAAY/AlVvcBybeuBfstxWzEKT/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAMSGh/9oACAEBAAE/IRsyKMvRHY16mOCngBTD9zpxTf/aAAwDAQACAAMAAAAQow//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAEDAQE/EDAu7f/EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8QrY7h/8QAHBABAQACAgMAAAAAAAAAAAAAAREAMSFhQYGx/9oACAEBAAE/EG7oIhEdfMoGMsTnSd5ewPnCJhCidmJgZSpGt4cVsDdbz7z/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Colton Birthday",
          "title": "Colton Birthday",
          "src": "/static/34ed2c2b4552ed91c8bf4be0ab44e620/8e1fc/colton_birthday_2.jpg",
          "srcSet": ["/static/34ed2c2b4552ed91c8bf4be0ab44e620/863e1/colton_birthday_2.jpg 225w", "/static/34ed2c2b4552ed91c8bf4be0ab44e620/20e5d/colton_birthday_2.jpg 450w", "/static/34ed2c2b4552ed91c8bf4be0ab44e620/8e1fc/colton_birthday_2.jpg 900w", "/static/34ed2c2b4552ed91c8bf4be0ab44e620/b768e/colton_birthday_2.jpg 1350w", "/static/34ed2c2b4552ed91c8bf4be0ab44e620/d9c39/colton_birthday_2.jpg 1800w", "/static/34ed2c2b4552ed91c8bf4be0ab44e620/d2602/colton_birthday_2.jpg 4032w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      